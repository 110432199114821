/* eslint-disable react/jsx-no-target-blank */
import { Ai, ArrowRight, ChevronDown, ChevronUp, Close } from '@vgno/icons';
import { useEffect, useState } from 'react';
import './Snakkis.css';
import Story from './Story.jsx';
import {
    addDebugDays,
    API_URL,
    delay,
    fetchWithTimeout,
    getLastSeen,
    nextFrame,
    trackEngagement,
    setLastSeen,
} from './utils.js';

import { trackImpression, trackLoad } from './tracking.js';

/**
 * @typedef {Object} Message
 * @property {string} uuid - Unique identifier for the message.
 * @property {string} story_name - Name of the story.
 * @property {string} short_summary - A short summary of the story.
 * @property {string[]} bullet_point_summary - Bullet-point summary of the story.
 * @property {string} source - Source identifier for the story.
 */

const Snakkis = ({ node }) => {
    const [stories, setStories] = useState([]);
    const [user, setUser] = useState(null);
    const [opened, setOpened] = useState(false);
    const [startStreamingIndex, setStartStreamingIndex] = useState(-1);
    const [title, setTitle] = useState('');
    const [shouldAnimate, setShouldAnimate] = useState(true);
    const [animationSpeed, setAnimationSpeed] = useState();

    const TITLES = [
        'Visste du at:', // Arnab
        'Er du oppdatert?', // Nora
        'På VG siden sist:', // Hanna
        'Før du scroller videre: Dette er nytt siden sist!', // Filip
        'Se hva som har skjedd mens du var borte:', // Stian
        'Siden sist: Alt du trenger å vite nå', // Stian
        'Mens du var borte: Det viktigste akkurat nå:', // Stian
        'Se hva som har skjedd siden sist', // Stian
        'Oppdatert på et blunk: Dette har skjedd', // Stian
        'Her er det viktigste du har gått glipp av:', // Nicolai
        '3 saker siden sist:', // Camilla
        'Dette er nytt i Norge og verden:', // Martin
        'Dine viktigste saker:', // Øystein
        'Viktigst for deg nå:', // Øystein
        'Fått med deg dette?', // Karne
        'Oppdater deg her:', // Karne
        'Oppdater deg på det viktigste:', // Johannes
        'Dette har skjedd siden sist du var innom:', // The good old one
    ];

    useEffect(() => {
        getIdentity().then((identity) => {
            identity.getUser().then((user) => {
                initialize(user);
            });
        });
    }, []);

    const initialize = async (user) => {
        try {
            const stories = await loadStories();

            setUser(user);

            const title = getTitleForUser(user.userId);
            setTitle(title);

            const shouldAnimate = getShouldAnimateForUser(user.userId);
            setShouldAnimate(shouldAnimate);

            const animationSpeed = getAnimationSpeedForUser(user.userId);
            setAnimationSpeed(animationSpeed);

            // Abort if there are no new stories
            if (stories.length == 0) return;

            const customTrackingData = {
                stories: stories.map((story) => story.story_name),
                title,
                shouldAnimate,
                animationSpeed,
            };

            trackLoad(customTrackingData);

            if (userIsInControlGroup(user.userId)) {
                console.log('User is in control group, aborting');
                setLastSeen();
                return;
            }

            trackImpression(node, customTrackingData);

            setStories(stories);
            showComponent();
        } catch (error) {
            console.error('Error initializing:', error);
        }
    };

    const loadStories = async () => {
        const url = new URL(API_URL + 'update');
        url.searchParams.append('stream', 'False');

        const lastSeen = getLastSeen();
        if (lastSeen) {
            url.searchParams.append('timestamp', lastSeen.toString());
        }

        addDebugDays(url);

        const response = await fetchWithTimeout(url.toString());
        const data = await response.json();

        return data.message;
    };

    const showComponent = async () => {
        node.style.setProperty('max-height', '0px');
        node.classList.add('snakkis-open');
        await delay(250);
        await nextFrame();
        node.style.setProperty('max-height', '200px');
        node.style.setProperty('min-height', '200px');
        node.style.setProperty('overflow', 'hidden');
    };

    const toggleOpen = () => {
        node.style.setProperty('transition', 'max-height 0.35s ease-in-out');
        node.style.setProperty(
            'max-height',
            opened ? '200px' : node.scrollHeight + 'px',
        );
        node.classList.toggle('snakkis-expanded');
        node.addEventListener(
            'transitionend',
            () => {
                node.style.setProperty('max-height', 'none');
            },
            { once: true },
        );

        trackEngagement('Click', {
            storyId: stories[0].uuid,
            contentId: opened ? 'show-less' : 'show-more',
            id: opened ? 'show-less' : 'show-more',
            name: stories[0].story_name,
            custom: { title, shouldAnimate, animationSpeed },
        });

        setOpened(!opened);
    };

    const getTitleForUser = (userId) => {
        // Subdivide segments 8, 9 and 10 into multiple sub-segments
        if (userId % 100 === 8 || userId % 100 === 9 || userId % 100 === 10) {
            const subSegment = Math.floor(userId / 100) % TITLES.length;
            return TITLES[subSegment];
        }
        return 'Dette har skjedd siden sist du var innom:';
    };

    const getShouldAnimateForUser = (userId) => {
        const states = [true, false];

        if (userId % 100 === 16) {
            const subSegment = Math.floor(userId / 100) % states.length;
            return states[subSegment];
        }

        return true;
    };

    const getAnimationSpeedForUser = (userId) => {
        const states = [false, 0, 15];

        if (userId % 100 === 17) {
            const subSegment = Math.floor(userId / 100) % states.length;
            return states[subSegment];
        }

        return 15;
    };

    const userIsInControlGroup = (userId) => {
        // Users whose 4th last digit being 1 are in the control group
        return Math.floor((userId / 1000) % 10) === 1;
    };

    return (
        <section className="custom-style extended snakkis">
            <div className="card">
                <h2 className="snakkis-title">
                    <span className="greeting">
                        Hei <span className="username">{user?.givenName}</span>!{' '}
                    </span>
                    {title}
                </h2>
                <ul className="story-list-items">
                    {stories.length === 0 && (
                        <li className="story-list-item">
                            <span className="summary-loading" />
                        </li>
                    )}
                    {stories.map((story, i) => (
                        <Story
                            key={story.uuid}
                            storyId={story.uuid}
                            shouldAnimate={shouldAnimate}
                            animationSpeed={animationSpeed}
                            startStreaming={startStreamingIndex === i - 1}
                            finishedStreamingCallback={() => {
                                setStartStreamingIndex(i);
                            }}
                        />
                    ))}
                </ul>

                <a
                    href="https://iter.ly/tvgm9"
                    target="_blank"
                    className="snakkis-feedback-link"
                >
                    Gi tilbakemelding <ArrowRight />
                </a>
                <div className="ai-disclaimer ai-disclaimer-text">
                    Sammendragene av VGs journalistikk siden ditt forrige besøk
                    lages med kunstig intelligens. Gi oss gjerne tilbakemelding
                    hvis du oppdager feil!
                </div>
            </div>

            <button
                className="snakkis-show-more"
                onClick={toggleOpen}
                onPointerUp={() => null}
            >
                {opened ? (
                    <>
                        Vis mindre
                        <ChevronUp />
                    </>
                ) : (
                    <>
                        Vis mer
                        <ChevronDown />
                    </>
                )}
            </button>
        </section>
    );

    async function getIdentity() {
        return new Promise((resolve) => {
            if (window.Identity) {
                resolve(window.Identity);
            } else {
                window.addEventListener('identity-initialized', () => {
                    resolve(window.Identity);
                });
            }
        });
    }
};

export default Snakkis;
